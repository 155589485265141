<template>
  <div class="scoped_cover_div">
    <div class="search-text">
      <input type="text" class="filter-text" @input="fetchPostalDocument()" v-model="filter.text" placeholder="Filter document type or name">
    </div>
    <div class="jobs_table sp2">
      <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
        <table>
           <tr>
            <th style="width: 19%;">Carrier Name</th>
            <th style="width: 19%;">Document Type</th>
            <th style="width: 19%;">Document Name</th>
            <th style="width: 19%;">Cost Center</th>
            <!-- <th style="width: 18%;">Unit Price</th> -->
            <!-- <th style="width: 16%;">Total Qty</th> -->
            <th style="width: 7.5%;">Margin</th>
            <th style="width: 7.5%;">References</th>
            <th style="width: 150px;">Action</th>
          </tr>
          <tr v-for="(data,i) in postaldocument" :key="i">
            <td>
              <ValidationProvider name="user" rules="required" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <select v-model="data.provider_id" :class="errors[0] ? 'warning-border':''"  :disabled="!data.is_edit_mode" style="width: 150px;" >
                  <option value="">Select Carrier Name</option>
                  <option v-for="(carrier,i) in carriers" :key="'carriers'+i" :value="carrier.id">{{carrier.name}}</option>
                </select>
              </div>
              </ValidationProvider>
            </td>
            <td>
              <ValidationProvider name="postal_document_type" rules="required|max:30" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <input
                  type="text"
                  placeholder="Enter Character Limit 30" :maxlength="max"
                  v-model="data.postal_document_type"
                  :disabled="!data.is_edit_mode" :class="errors[0] ? 'warning-border':''" style="width: 150px;"
                />
              </div>
              </ValidationProvider>
            </td>
            <td>
              <ValidationProvider name="postal_document_name" rules="required|max:30" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <input
                  type="text"
                  placeholder="Enter Character Limit 30" :maxlength="max"
                  v-model="data.postal_document_name"
                  :disabled="!data.is_edit_mode" :class="errors[0] ? 'warning-border':''" style="width: 150px;"
                />
              </div>
              </ValidationProvider>
            </td>
            <td>
              <ValidationProvider name="user" rules="required" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                 <select v-model="data.cost_center_id" :disabled="!data.is_edit_mode" :class="errors[0] ? 'warning-border':''" style="width: 150px;">
                    <option value="" disabled>Select Cost Centre </option>
                    <option v-for="(costcenter,i) in costcenters" :key="'costcenter'+i" :value="costcenter.id">{{costcenter.name}}</option>
                  </select>
              </div>
              </ValidationProvider>
            </td>
            <!-- <td>
              <ValidationProvider name="postal_document_name" rules="required|max:30" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <input disabled type="text" placeholder="Enter Unit Price" :value="data.unit_price" :maxlength="max" :class="errors[0] ? 'warning-border':''"  style="width: 93px;"
                />
              </div>
              </ValidationProvider>
            </td> -->
            <!-- <td>
              <ValidationProvider name="postal_document_name" rules="required|max:30" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <input disabled type="text"  placeholder="Enter Total Qty" :maxlength="max" :value="data.total_qty" :class="errors[0] ? 'warning-border':''" style="width: 93px;"
                />
              </div>
              </ValidationProvider>
              <a href="javascript:void(0)" class="table_action_btn" v-if="data.is_edit_mode" title="DELETE" @click="addStockModal(data)" style="margin-bottom: 10px;">ADD QTY</a>
            </td> -->
            <td>
              <div class="label_and_element_wrapper">
                   <div class="newdiv _sur_newdiv" >
                      <div style="display: flex;width: 100%;">
                          <label class="radio-inline" style="padding-top: 12px;">
                          Per
                          </label>
                          <input type="radio" v-model="data.markup_margin_type" :checked="data.markup_margin_type =='per'" value="per" @change="onChangeMarkupMargin($event,i)" :disabled="!data.is_edit_mode" > 

                          <input type="text"  placeholder="Enter" :maxlength="max" v-model="data.markup_margin_per" :disabled="data.markup_margin_type=='fix' || !data.is_edit_mode" style="width: 100px;"/>

                          <label class="radio-inline" style="padding-top: 12px;margin-left: 10px;">
                          Fixed
                          </label> 
                          <input type="radio" v-model="data.markup_margin_type" :checked="data.markup_margin_type =='fix'" value="fix" @change="onChangeMarkupMargin($event,i)"  :disabled="!data.is_edit_mode"> 

                          <input type="text"  placeholder="Enter" :maxlength="max" v-model="data.markup_margin_fix" :disabled="data.markup_margin_type=='per' ||!data.is_edit_mode"  style="width: 100px;"/>
                        
                        </div>
                  </div>
              </div>
              
            </td>
            <td>
              <div class="label_and_element_wrapper">
                   <div class="newdiv _sur_newdiv" >
                    <div style="display: flex;">
                      <input type="checkbox" :id="`reference_required_${i}`" v-model="data.reference_required" :disabled="!data.is_edit_mode">
                      <label :for="`reference_required_${i}`">Reference Required</label>
                    </div>
                  </div>
              </div>
            </td>
            <td class="width_50">
              <a class="table_action_btn"
                title="DELETE"
                @click="deleteDispatch(data.id)"
                style="margin-bottom: 10px;"
                v-if="!data.is_edit_mode"
              >DELETE</a>
              <a
                class="table_action_btn"
                title="CANCEL"
                v-if="data.is_edit_mode"
                @click="cancelPostalDocument()"
                style="margin-bottom: 10px;"
              >CANCEL</a>
              <a
                class="table_action_btn"
                title="UPDATE"
                v-if="data.is_edit_mode"
                @click="updatePostalDocument(data)"
                style="margin-bottom: 10px;"
              >UPDATE</a>
              <a
                class="table_action_btn"
                title="EDIT"
                @click="data.is_edit_mode = true"
                v-if="!data.is_edit_mode"
                style="margin-bottom: 10px;"
              >EDIT</a>
              <a
                class="table_action_btn"
                title="EDIT"
                @click="addStockModal(data)"
                style="margin-bottom: 10px;"
                v-if="!data.is_edit_mode"
              >MANAGE</a>
              <a
                class="table_action_btn"
                title="EDIT"
                @click="viewStockHistory(data)"
                v-if="!data.is_edit_mode"
              >STOCK</a>
            </td>
          </tr>
          <tr v-if="filter.text != '' && postaldocument.length < 1">
            <td colspan="7" style="text-align: center; font-size: 20px; font-weight: 600;padding-block: 10px;">No Postal Document Types Found</td>
          </tr>
        </table>
      </ValidationObserver>
    </div>
    <div class="black-overlay" v-if="selectedData">
      <div class="box-modal" style="background:#fff;">
        <div class="body">
          <AddBranchStock v-if="selectedData" :item="selectedData" @closeModal="fetchPostalDocument" />
        </div>
      </div>
    </div>
    <div class="black-overlay" v-if="showData">
      <div class="box-modal" style="background:#fff;">
        <div class="body">
          <StockHistory v-if="showData" :item="showData" @closeModal="fetchPostalDocument" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AddBranchStock from './PostalDocumentType_AddBranchStock.vue'
import StockHistory from './PostalDocumentType_StockHistory.vue'
export default {
  name: "PostalDocumentType",
  components: {
    AddBranchStock,
    StockHistory,
  },
  data() {
    return {
      max:30,
      makeEditable: false,
      cancelEditable: true,
      postaldocument: [],
      carriers:[],
      costcenters:[],
      selectedData: null,
      showData: null,
      filter: {
        text:'',
      }
    };
  },
  async created() {
    await this.fetchPostalDocument();
    await this.fetchCarriers();
    await this.fetchCostCenter();
  },
  methods: {
    addStockModal(data) {
      this.selectedData = data
    },
    viewStockHistory(data) {
      this.showData = data
    },
    fetchCarriers(){
      this.axios.get("/api/provider")
      .then(response => {
          this.carriers = response.data.providers;
      })
      .catch(error =>{
          this.toast.error();
      });
    },

    fetchCostCenter(){
      this.axios.get("/api/cost")
      .then(response => {
          this.costcenters = response.data.cost_centers;
      })
      .catch(error =>{
          this.toast.error();
      });
    },
  onChangeMarkupMargin(event,i) {
     var optionText = event.target.value;
      if(optionText =='fix')
        this.postaldocument[i].markup_margin_per='';

      if(optionText =='per')
        this.postaldocument[i].markup_margin_fix='';
},
    fetchPostalDocument() {
      this.selectedData = null
      this.showData = null
      this.axios
        .get("/api/postaldocument", {
          params: this.filter
        })
        .then(response => {
          this.postaldocument = response.data.postal_document_types.map(element => {
            element.is_edit_mode = false;
            element.markup_margin_fix = element.markup_margin_type =='fix' ? element.markup_margin :"";
            element.markup_margin_per = element.markup_margin_type =='per' ? element.markup_margin :"";

            return element;
          });
        })
        .catch(error => {
          console.log(error);
        });
    },
    updatePostalDocument(data) {
     
      this.$refs.observer.validate();
      data.markup_margin =data.markup_margin_per != ''? data.markup_margin_per : data.markup_margin_fix;
      console.log(JSON.stringify(data))
      data['method'] = 'record_update';
      this.axios
        .put("/api/postaldocument/" + data.id, data)
        .then(response => {
          this.toast.success(response.data.msg);
          data.is_edit_mode = false;
        })
        .catch(error => {
          console.log("error", error.response);
        });
    },
    deleteDispatch(id) {
      if (confirm("Are you sure you want to delete this postal document type?")) {
        this.axios
          .delete("/api/postaldocument/" + id)
          .then(response => {
            this.toast.success(response.data.msg);
            this.fetchPostalDocument();
          })
          .catch(error => {
            console.log("error", error.response);
            this.toast.error();
          });
      }
    },
    cancelPostalDocument(){
      this.fetchPostalDocument();
    }
  }
};
</script>
<style  scoped >
.scoped_cover_div {
  margin: 0;
}
.scoped_cover_div section {
  padding: 0;
}
.scoped_cover_div .form_section1 {
  margin-top: 0;
}

.jobs_table.sp2 table tr td {
  padding: 5px 10px;
}

.table_action_btn {
  width: 80px;
  height: 40px;
  line-height: 40px;
  text-align-last: center;
  background: #06A5ED;
  color: #000;
  font-weight: 700;
  display: inline-block;
  margin: 0 5px;
}

.width_50 {
  width: 50%;
}
.righted {
  text-align: right;
}
.search-text {
  background-color: #004a7c;
  margin: 0px;
  width: 100%;
  padding: 15px;
  display: flex;
  .filter-text {
    padding: 5px;
    font-size: 1rem;
    width:30%;
    height: 40px;
  }
}
</style>